<template>
  <b-modal
      :no-enforce-focus = 'true'
      ref="modalSelectAddress"
      id="modalSelectAddress"
      size='lg'
      @hidden="modalSelectAddressOnHidden"
      class="modal-top">
      <div slot="modal-title">
        {{ 'Select address' |translate}}
      </div>
      <thux-table
        :instance="instance"
        :instance-name="instanceName"
        :order-by-dict="orderByDict"
        :goBack="goBack"
        :list="list"
        :local-selected-list="[]"
        :selected-list="[]"
        :fields-column="[]"
        :fieldName="fieldName"
        :fields="fields"
        :show-checks="false"
        :showDetailButton="false"
      >
        <template slot="other-buttons-actions-bottom" slot-scope="row">
          <b-btn
            variant="outline-primary"
            :title="'Select' | translate"
            @click.prevent="$emit('close-select-address', row.item)"
          >
            <i class="fas fa-check"></i>
          </b-btn>
        </template>
      </thux-table>
       <div slot="modal-footer" class="w-100">
       </div>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_IPA_ADDRESS_TYPES } from './store'
import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'

export default {
  name: 'SelectAddressModal',
  mixins: [ThuxTableMixin],
  props: ['orgId'],
  data () {
    return {
      fields: [
        {
          key: 'recipient',
          sortable: false,
          label: this.$t('Recipient'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'recipient'
        },
        {
          key: 'name',
          sortable: false,
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'full_address'
        },
        {
          key: 'ipa_code',
          sortable: false,
          label: this.$t('IPA code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'ipa_code'
        },
        {
          key: 'cig_code',
          sortable: false,
          label: this.$t('CIG code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'cig_code'
        },
        {
          key: 'cup_code',
          sortable: false,
          label: this.$t('CUP code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'cup_code'
        },
        {
          key: 'action',
          sortable: false,
          label: '',
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ]
    }
  },
  mounted () {
    this.openModalSelectAddress()
  },
  methods: {
    openModalSelectAddress () {
      this.$refs.modalSelectAddress.show()
    },
    modalSelectAddressOnHidden (evt) {
      this.$refs.modalSelectAddress.hide()
      this.$emit('close-select-address')
    },
    ...mapMutations({
      setList: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.MUTATIONS.setList,
      setSelectedList:
        ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.ACTIONS
    })
  },
  computed: {
    ...mapGetters({
      selectAll: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.GETTERS.selectAll,
      selectedList: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.GETTERS.selectedList
    })
  },

  created () {
    this.setList([])
    if (this.orgId) {
      const filters = { no_page: 'no_page', filter__holder__id: this.orgId, filter__status: 1 }
      this.setFilters(filters)
    }
  }
}
</script>
