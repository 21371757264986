<template>
  <div class="panel-detail panel-fixed panel-with-actions">
    <div class="panel-header">
      <h1 class="page-header">
        {{ `${$t('Invoice')}` }}
        <div class="page-header-actions" v-if="$route.params.id && invoiceDetail">
          <b-btn
            variant="outline-primary"
            v-has-perms="sendInvoiceByEmailRolePerm"
            :title="'Send invoice by email' | translate"
            :disabled="invoiceDetail.is_closed"
            @click.prevent="showModal()"
          >
            <i class="fas fa-envelope"></i>
          </b-btn>

          <b-btn
            variant="outline-primary"
            v-has-perms="downloadPDFRolePerm"
            :disabled="invoiceDetail.order_done"
            :title="'Download PDF' | translate"
            @click.prevent="downloadPDF()"
          >
            <i class="fas fa-file-download"></i>
          </b-btn>

          <b-btn
            variant="outline-primary"
            v-has-perms="downloadXMLRolePerm"
            :disabled="invoiceDetail.order_done"
            :title="'Download XML' | translate"
            @click.prevent="downloadXML()"
          >
            <i class="fas fa-file-invoice"></i>
          </b-btn>
        </div>
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span @click="goToList" class="underline-on-hover">
            {{ 'Invoices' | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ 'Invoice' | translate }}
          </span>
        </li>
      </ol>
    </div>
    <invoice-edit :id="$route.params.id"/>
    <send-email-modal
      v-if="showSendEmailModal && invoiceDetail && invoiceDetail.id"
      :show-owner-emails='true'
      :title="$t('Send invoice by email')"
      @submit="sendInvoiceEmail"
      :default-email="invoiceDetail.recipient_default_email"
      @close="closeModal"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { ROUTES as INVOICE_ROUTES } from './router'
import InvoiceEdit from './InvoiceEdit'
import { TYPES as INVOICE_TYPES } from './store'
import { mapActions, mapGetters } from 'vuex'
import SendEmailModal from '@/components/SendEmailModal'
import JobMixin from '@/components/job-button/JobMixin'

export default {
  name: 'InvoiceEditPage',
  mixins: [JobMixin],
  components: {
    'invoice-edit': InvoiceEdit,
    'send-email-modal': SendEmailModal
  },
  data () {
    return {
      pageName: 'Invoice',
      form: {},
      showSendEmailModal: false,
      // Permissions
      sendInvoiceByEmailRolePerm: 'invoice_invoice_send_invoice',
      downloadPDFRolePerm: 'invoice_invoice_print_pdf',
      downloadXMLRolePerm: 'invoice_invoice_print_xml'
    }
  },
  created () {
    if (this.$route.params.id) {
      this.retrieve({ id: this.$route.params.id })
    }
  },
  computed: {
    ...mapGetters({
      invoiceDetail: INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS.detail,
      apiUrl: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS.apiUrl
    })
  },
  methods: {
    ...mapActions({
      retrieve: INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.ACTIONS.retrieve,
      download: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.ACTIONS.download
    }),
    goToList () {
      this.$router.push({ name: INVOICE_ROUTES.INVOICE_INVOICE_LIST })
    },
    showModal () {
      this.$set(this, 'showSendEmailModal', true)
    },
    sendInvoiceEmail (emails) {
      this.$set(this, 'apiCallJobUrl', `${this.apiUrl}/${this.invoiceDetail.id}/send-invoice/`)
      this.doJob({ emails: emails })
    },
    closeModal () {
      this.$set(this, 'showSendEmailModal', false)
    },
    downloadPDF () {
      const fileName = `${this.$t('Invoice')}_${this.invoiceDetail.number}_${this.invoiceDetail.date}.pdf`
      this.download({ id: this.invoiceDetail.id, action: 'print-pdf', simpleFileName: fileName })
    },
    downloadXML () {
      const dateObj = moment(new Date(this.invoiceDetail.date))
      const fileName = `${this.$t('Invoice')}_${this.invoiceDetail.number}_${dateObj.format('DD_MM_YYYY')}.xml`
      this.download({ id: this.invoiceDetail.id, action: 'print-xml', simpleFileName: fileName })
    }
  }
}
</script>
