<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as INVOICE_TYPES } from '../store'

export default {
  name: 'TypologyMixin',
  created () {
    if (!this.invoiceTypologyChoicesList || (this.invoiceTypologyChoicesList && this.invoiceTypologyChoicesList.length === 0)) {
      this.getInvoicetypologychoiceslist()
    }
  },
  computed: {
    ...mapGetters({
      invoiceTypologyChoicesList: INVOICE_TYPES.GENERIC.invoice.invoice.LIST.GETTERS.invoiceTypologyChoicesList
    }),
    typologyListOptions () {
      const options = []
      if (this.invoiceTypologyChoicesList && this.invoiceTypologyChoicesList.length > 0) {
        this.invoiceTypologyChoicesList.forEach((instance) => {
          options.push({
            value: instance[0],
            text: instance[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getInvoicetypologychoiceslist:
        INVOICE_TYPES.GENERIC.invoice.invoice.LIST.ACTIONS.getInvoicetypologychoiceslist
    }),
    selectInvoiceTypology (item) {
      this.$set(this.form, 'typology', item.value)
      this.$set(this.form, 'get_typology_display', item.text)
    },
    clearInvoiceTypology () {
      this.$set(this.form, 'typology', null)
      this.$set(this.form, 'get_typology_display', null)
    }
  }
}
</script>
