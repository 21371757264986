<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as PAYMENT_FORM_TYPES } from '../../../payment/payment-form/store'

export default {
  name: 'PaymentFormMixin',
  created () {
    if (!this.paymentFormList || (this.paymentFormList && this.paymentFormList.length === 0)) {
      this.setPaymentFormList({})
    }
  },
  computed: {
    ...mapGetters({
      paymentFormList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.GETTERS.list
    }),
    paymentFormListOptions () {
      const options = []
      if (this.paymentFormList && this.paymentFormList.results && this.paymentFormList.results.length > 0) {
        this.paymentFormList.results.forEach((payment) => {
          options.push({
            value: payment.id,
            name: payment.electronic_invoice_code ? `${payment.name} (${payment.electronic_invoice_code})` : payment.name
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setPaymentFormList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getPaymentFormList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.ACTIONS.setFilters
    }),
    onSearchPaymentForm (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getPaymentFormList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setPaymentFormList({})
      }
    },
    selectPaymentForm (item) {
      this.$set(this.invForm, 'payment_form', item.value)
      this.$set(this.invForm, 'payment_form_name', item.name)
      this.$emit('change-payment-form')
    },
    clearPaymentForm () {
      this.$set(this.invForm, 'payment_form', null)
      this.$set(this.invForm, 'payment_form_name', null)
    }
  }
}
</script>
