<template>
  <panel :title="'Invoice data' | translate" noButton="true">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.date"
          :label-form="'Date' | translate"

          :disabled="!formEditableUpdate"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="dateI"
          :placeholder="$t('Enter the date')"
          @change="setDateI"
        />
        <form-thux-horizontal-input
          :validator="$v.form.number"
          :label-form="'Number' | translate"
          class-form="col-lg-3 mb-2 mt-2"
          type="text"
          labelColsLg="4"
          :value="$v.form.number.$model"
          :disabled="true"
          @change="$set(form, 'number', $event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.invoice_year"
          :label-form="'Year' | translate"
          class-form="col-lg-3 mb-2 mt-2"
          type="text"
          labelColsLg="4"
          :value="$v.form.invoice_year.$model"
          :disabled="true"
          @change="$set(form, 'invoice_year', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.typology"
          :external-errors="errors['typology']"
          :label-form="'Typology' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          :disabled="!formEditableUpdate"
          label-search="text"
          :value="form.typology ? { value: form.typology, text: form.get_typology_display } : null"
          :placeholder="$t('Select an option')"
          :options="typologyListOptions"
          @select="selectInvoiceTypology"
          @clear="clearInvoiceTypology"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.form.has_split_payment"
          :external-errors="errors['has_split_payment']"
          :label-form="'Split Payment' | translate"
          class-form="col-lg-3 mb-2"
          label-cols-lg="4"
          label-cols-sm="4"
          :disabled="!formEditableUpdate"
          :value="form.has_split_payment"
          @change="$set(form, 'has_split_payment', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-autocomplete
          :validator="$v.form.recipient"
          :label-form="'Customer' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          :disabled="!formEditableUpdate"
          :label-search="'text'"
          :value="form.recipient ? { value: form.recipient, text: form.recipient_typology_name ? `${form.recipient_name} (${form.recipient_typology_name})` : form.recipient_name } : null"
          :options="recipientListOptions"
          :placeholder="$t('Type a customer')"
          :placeholder-field="form.recipient_name"
          @search="onSearchRecipient"
          @select="selectRecipient"
          @clear="clearRecipient"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-text-area
          :validator="$v.form.note"
          :label-form="'Note' | translate"
          class-form="col-12 mb-2 mt-2"
          rows="3"
          max-rows="6"
          labelColsLg="1"
          :value="$v.form.note.$model"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'note', $event)"
        />
      </b-form-row>
    </b-form>
  </panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import RecipientMixin from '../mixins/RecipientMixin'
import TypologyMixin from '../mixins/TypologyMixin'
import { TYPES as INVOICE_TYPES } from '../store'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/store'
import moment from 'moment'

export default {
  name: 'InvoiceEditForm',
  mixins: [ThuxDetailPanelMixin, RecipientMixin, TypologyMixin],
  components: {
  },
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {}
  },
  watch: {
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    proposal () {
      this.init()
    },
    resetForm () {
      this.init()
    },
    id () {
      this.init()
    }
  },
  data () {
    return {
      dateI: undefined,
      dateFirmFor: undefined,
      supplierContacts: []
    }
  },
  computed: {
    ...mapGetters({
      detail: INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS.detail,
      recipientDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail
    })
  },
  methods: {
    ...mapActions({
      getRecipientDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.retrieve
    }),
    init () {
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      if (this.detail.date) {
        this.$set(this, 'dateI', moment(new Date(this.detail.date)))
      }
      this.$set(this, 'form', form)
    },
    setDateI (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'date', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'date', undefined)
      }
    },
    selectRecipient (item) {
      this.$set(this.form, 'recipient', item.value)
      this.$set(this.form, 'recipient_name', item.text)
      this.$set(this.form, 'recipient_typology_name', null)
      this.getRecipientDetail({ id: item.value }).then(
        () => {
          this.$emit('change-owner', this.recipientDetail)
        }
      )
    },
    clearRecipient () {
      this.$set(this.form, 'recipient', null)
      this.$set(this.form, 'recipient_name', null)
      this.$set(this.form, 'recipient_typology_name', null)
      this.$emit('change-owner', undefined)
    }
  },
  validations () {
    const validator = {
      form: {
        date: { required },
        number: { required },
        invoice_year: { required },
        recipient: { required },
        typology: { required },
        note: { },
        has_split_payment: { }
      }
    }
    return validator
  }
}
</script>
