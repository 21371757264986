<template>
  <panel :title="$t('Recipient address')" noButton="true">
    <div>
      <b-form class="mb-3" v-if="showselectAddressButton" >
        <b-button class="mr-2" size="md" variant="primary" @click.prevent="openSelectAddressModal()">
          <i class="fas fa-map-pin mr-1"></i>
          {{ 'Select address' | translate }}
        </b-button>
      </b-form>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.recipient"
            :external-errors="addressErrors['recipient']"
            :label-form="'Customer' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.recipient"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'recipient', $event)"
          />
          <form-thux-horizontal-input v-if="form.recipient_typology !== 'PA'"
            :validator="$v.form.sdi_code"
            :external-errors="addressErrors['sdi_code']"
            :label-form="'SDI' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.sdi_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'sdi_code', $event)"
          />
          <form-thux-horizontal-input v-if="form.recipient_typology === 'PA'"
            :validator="$v.form.sdi_code"
            :external-errors="addressErrors['sdi_code']"
            :label-form="'Unique Office Code' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.sdi_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'sdi_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.ssn"
            :external-errors="addressErrors['ssn']"
            :label-form="'SSN' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.ssn"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'ssn', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.vat_number"
            :external-errors="addressErrors['vat_number']"
            :label-form="'VAT number' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.vat_number"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'vat_number', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.phone"
            :external-errors="addressErrors['phone']"
            :label-form="'Phone' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.phone"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'phone', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.email"
            :external-errors="addressErrors['email']"
            :label-form="'Email' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.email"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'email', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.address_type"
            :external-errors="addressErrors['address_type']"
            :label-form="'Address Type' | translate"
            class-form="col-6 mb-2 mt-2"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            :value="form.address_type ? { value: form.address_type, name: form.address_type_name } : null"
            :options="addressTypeListOptions"
            :placeholder="$t('Select a address type')"
            :placeholder-field="form.address_type_name"
            @select="selectAddressType"
            @clear="clearAddressType"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="addressErrors['name']"
            :label-form="'Address' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.name"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.number"
            :external-errors="addressErrors['number']"
            :label-form="'Number' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.number"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'number', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.zip_code"
            :external-errors="addressErrors['zip_code']"
            :label-form="'CAP' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.zip_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'zip_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.locality"
            :external-errors="addressErrors['locality']"
            :label-form="'Locality' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.locality"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'locality', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.province"
            :external-errors="addressErrors['province']"
            :label-form="'Province (abbr.)' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            maxlength="2"
            :value="form.province"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'province', $event ? $event.toUpperCase() : null)"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.country"
            :external-errors="addressErrors['country']"
            :label-form="'Country' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            :value="form.country ? { value: form.country, name: form.country_name } : null"
            :options="countryListOptions"
            :placeholder="$t('Type a country')"
            :placeholder-field="form.country_name"
            @select="selectCountry"
            @clear="clearCountry"
          />
        </b-form-row>
      </b-form>
    </div>
    <select-address-modal v-if="showSelectAddressModal" :orgId="newRecipient.id" v-on:close-select-address="closeSelectAddressModal"></select-address-modal>
  </panel>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { TYPES as INVOCE_TYPES } from '../store'
import { TYPES as RECIPIENT_INVOICE_ADDRESS_TYPES } from '../../recipient-invoice-address/store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import CountryMixin from '../../../organization/organization/organization-address/mixins/CountryMixin'
import AddressTypeMixin from '../../../organization/organization/organization-address/mixins/AddressTypeMixin'
import SelectAddressModal from '../../../proposal/owner-proposal-address/SelectAddressModal.vue'

export default {
  name: 'RecipientInvoiceAddressEditForm',
  components: {
    SelectAddressModal
  },
  mixins: [
    ThuxDetailPanelMixin, CountryMixin, AddressTypeMixin
  ],
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {},
    owner: {},
    changeRecipient: {},
    newRecipient: {}
  },
  watch: {
    detail () {
      this.init()
    },
    recipientInvoiceAddressDetail: {
      handler () {
        if (this.recipientInvoiceAddressDetail) {
          this.$set(this, 'form', this.recipientInvoiceAddressDetail)
        }
      }
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    formEditableUpdate () {
      this.$set(this, 'formEditable', this.formEditableUpdate)
    },
    resetForm () {
      if (this.recipientInvoiceAddressDetail) {
        this.$set(this, 'form', this.recipientInvoiceAddressDetail)
      }
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    },
    changeRecipient () {
      this.$set(this, 'form', {})
      if (this.newRecipient) {
        this.$set(this.form, 'recipient', this.newRecipient.name)
        this.$set(this.form, 'sdi_code', this.newRecipient.sdi_code)
        this.$set(this.form, 'ssn', this.newRecipient.ssn)
        this.$set(this.form, 'vat_number', this.newRecipient.vat_number)
        this.$set(this.form, 'phone', this.newRecipient.default_phone)
        this.$set(this.form, 'email', this.newRecipient.default_email)
        this.$set(this, 'showselectAddressButton', true)
      } else {
        this.$set(this, 'showselectAddressButton', false)
      }
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    }
  },
  data () {
    return {
      form: {},
      showselectAddressButton: false,
      showSelectAddressModal: false,
      selectedAddress: {},
      resetFormAddress: false
    }
  },
  computed: {
    ...mapGetters({
      addressErrors: RECIPIENT_INVOICE_ADDRESS_TYPES.GENERIC.invoice.recipientinvoiceaddress.DETAIL.GETTERS.errors,
      recipientInvoiceAddressDetail: RECIPIENT_INVOICE_ADDRESS_TYPES.GENERIC.invoice.recipientinvoiceaddress.DETAIL.GETTERS.detail,
      detail: INVOCE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS.detail
    })
  },
  methods: {
    init () {
      this.$set(this, 'showselectAddressButton', false)
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      }
    },
    openSelectAddressModal () {
      this.showSelectAddressModal = true
    },
    closeSelectAddressModal (evtData) {
      if (evtData) {
        this.$set(this.form, 'address_type', evtData.address_type)
        this.$set(this.form, 'address_type_name', evtData.address_type_name)
        this.$set(this.form, 'name', evtData.name)
        this.$set(this.form, 'number', evtData.number)
        this.$set(this.form, 'zip_code', evtData.zip_code)
        this.$set(this.form, 'locality', evtData.locality)
        this.$set(this.form, 'province', evtData.province)
        this.$set(this.form, 'country', evtData.country)
        this.$set(this.form, 'country_name', evtData.country_name)
      }
      this.showSelectAddressModal = false
    }
  },
  validations () {
    const validator = {
      form: {
        recipient: { required },
        ssn: { required },
        vat_number: { },
        phone: { required },
        email: { required },
        address_type: {},
        name: { required },
        sdi_code: {},
        number: {
          maxLength: maxLength(20)
        },
        zip_code: {
          required,
          maxLength: maxLength(20)
        },
        locality: { required },
        province: {
          required,
          maxLength: maxLength(2)
        },
        country: { required }
      }
    }
    // validator.form = this.getAddressValidator(validator.form)
    return validator
  }
}
</script>
