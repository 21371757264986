<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.id_row"
        :label-form="'Row num.' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.id_row.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="true"
        @change="$set(form, 'id_row', $event)"
      />
      <form-thux-horizontal-text-area
        :validator="$v.form.description"
        :label-form="'Description' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.description.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'description', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.amount_total"
        :label-form="'Income' | translate"
        class-form="input-row-col my-2 pl-2"
        type="number"
        :min="0"
        :value="$v.form.amount_total.$model"
        placeholder="€"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="true"
        @change="$set(form, 'amount_total', $event)"
      />
      <form-thux-horizontal-autocomplete
        :validator="$v.form.vat_code"
        :label-form="'VAT' | translate"
        class-form="input-row-col input-col-sm mb-2 mt-2"
        :disabled="true"
        :customize-option="true"
        labelColsSm="0"
        labelColsLg="0"
        label-search="name"
        :value="form.vat_code ? { value: $v.form.vat_code.$model, name: $v.form.vat_name.$model } : null"
        :options="vatListOptions"
        :placeholder="$t('VAT')"
        :placeholder-field="form.vat_name ? form.vat_name.toString() : null"
        @select="selectVat"
        @clear="clearVat"
      />
      <form-thux-horizontal-text-area
        :validator="$v.form.vat_description"
        :label-form="'VAT Description' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.vat_description.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'vat_description', $event)"
      />
    </b-form-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import VatMixin from '../../../../product/product/mixins/VatMixin'

export default {
  name: 'InvoiceRowForm',
  mixins: [ThuxDetailPanelMixin, VatMixin],
  props: {
    formUpdate: {},
    index: {},
    formEditableUpdate: {}
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: { },
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
      }
      this.$watch('form', () => {
        this.$emit('edit-row', this.form)
      }, { deep: true })
    }
  },
  validations () {
    const form = {
      id_row: { required },
      description: { required },
      amount_total: { required },
      vat_code: {},
      vat_aliquot_perc: { },
      vat_description: {},
      vat_name: { required }
    }
    return { form: form }
  }
}
</script>
