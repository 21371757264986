<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/store'

export default {
  name: 'RecipientMixin',
  created () {
    if (!this.recipientList || (this.recipientList && this.recipientList.length === 0)) {
      this.setRecipientList({})
    }
  },
  computed: {
    ...mapGetters({
      recipientList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list,
      recipientDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail
    }),
    recipientListOptions () {
      const options = []
      if (this.recipientList && this.recipientList.results && this.recipientList.results.length > 0) {
        this.recipientList.results.forEach((customer) => {
          options.push({
            value: customer.id,
            text: customer.typology_name ? `${customer.name} (${customer.typology_name})` : customer.name
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setRecipientList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList,
      setRecipientDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      getRecipientList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters,
      getRecipientDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.retrieve
    }),
    onSearchRecipient (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getRecipientList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setRecipientList({})
      }
    },
    selectRecipient (item) {
      this.$set(this.form, 'recipient', item.value)
      this.$set(this.form, 'recipient_name', item.text)
      this.$set(this.form, 'recipient_typology_name', null)
    },
    clearRecipient () {
      this.$set(this.form, 'recipient', null)
      this.$set(this.form, 'recipient_name', null)
      this.$set(this.form, 'recipient_typology_name', null)
    }
  }
}
</script>
