<template>
  <div v-has-perms="rolePerm">
    <invoice-edit-form
      :id="detail.id"
      :form-editable-update="formEditable"
      :errors="errors"
      :reset-form="resetForm"
      @set-form="updateForm"
      @change-owner="changeInvoiceOwner"
    />
    <recipient-address-edit-form
      :id="detail.id"
      :change-recipient="changeRecipient"
      :new-recipient="newRecipient"
      :form-editable-update="formEditable"
      :reset-form="resetForm"
      @set-form="setRecipientAddressForm"
    />
    <recipient-ipa-address-edit-form
      v-show="showIpaAddressComponent()"
      :id="detail.id"
      :change-recipient="changeRecipient"
      :new-recipient="newRecipient"
      :form-editable-update="formEditable"
      :reset-form="resetForm"
      @set-form="setIpaAddressForm"
    />
    <invoice-bank-edit-form
      :id="detail.id"
      :form-editable-update="formEditable"
      :reset-form="resetForm"
      @set-form="setInvoiceBankForm"
      @update-invoice-form="updateInvoiceForm"
    />
    <invoice-row-manager
      :form-editable-update="formEditable"
      :invoice-rows="invoiceRowList"
      @update-rows="updateRows"
    />
    <invoice-vat-row-manager
      :form-editable-update="false"
      :invoice-vat-rows="invoiceVatRowList"
    />
    <invoice-totals
      :form-editable-update="false"
      :total-vat="form.total_vat"
      :total-income="form.total_income"
      :total-amount="form.total_amount"
    />
    <div class="panel-actions">
      <b-button
        size="lg"
        class="mr-3"
        @click.prevent="formEditable ? cancel() : goToList()"
      >
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
          v-if="!this.detail || this.detail.status !== 1 "
          size="lg"
          variant="primary"
          v-has-perms="editRolePerm"
          :disabled="formEditable ? $v.$invalid : false"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
      >
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkIfEqual } from '@/const'
import { TYPES as INVOICE_TYPES } from './store'
import { TYPES as RECIPIENT_INVOICE_TYPES } from '../recipient-invoice-address/store'
import { TYPES as ORGANIZATION_IPA_ADDRESS_TYPES } from '../organization-ipa-address/store'
import { TYPES as INVOICE_ISSUER_BANK_TYPES } from '../invoice-issuer-bank/store'
import { TYPES as INVOICE_ROW_TYPES } from '../invoice-row/store'
import { TYPES as VAT_ROW_TYPES } from '../vat-row/store'
import { ROUTES as INVOICE_ROUTES } from '../invoice/router'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import InvoiceEditForm from './components/InvoiceEditForm'
import InvoiceBankEditForm from './components/InvoiceBankEditForm'
import RecipientInvoiceAddressEditForm from './components/RecipientInvoiceAddressEditForm'
import RecipientIpaAddressEditForm from './components/RecipientIpaAddressEditForm'
import InvoiceRowManager from './components/invoice-row-form/InvoiceRowManager'
import VatRowManager from './components/vat-row-form/VatRowManager'
import InvoiceTotals from './components/InvoiceTotals'

export default {
  name: 'InvoiceEdit',
  mixins: [ThuxDetailPanelMixin],
  components: {
    'invoice-edit-form': InvoiceEditForm,
    'recipient-address-edit-form': RecipientInvoiceAddressEditForm,
    'recipient-ipa-address-edit-form': RecipientIpaAddressEditForm,
    'invoice-bank-edit-form': InvoiceBankEditForm,
    'invoice-row-manager': InvoiceRowManager,
    'invoice-vat-row-manager': VatRowManager,
    'invoice-totals': InvoiceTotals
  },
  data () {
    return {
      pageName: 'Invoice',

      // Permissions
      rolePerm: 'invoice_invoice_retrieve',
      editRolePerm: ['invoice_invoice_update', 'invoice_invoicerow_update', 'invoice_recipientinvoiceaddress_update', 'invoice_invoiceissuerbank_update'],

      // FORM
      form: {},
      formInitial: {},
      isFormChanged: false,
      resetForm: false,
      changeRecipient: false,
      newRecipient: undefined,

      // FORM INVOICE
      formInvoiceAddress: {},
      formInvoiceAddressInitial: {},
      isFormInvoiceAddressChanged: false,
      resetInvoiceAddress: false,

      // INVOICE RECIPIENT ADDRESS FORM
      invoiceRecipientAddressForm: {},
      invoiceRecipientAddressFormInitial: {},
      isInvoiceRecipientAddressFormChanged: false,
      resetInvoiceRecipientAddress: false,

      // INVOICE RECIPIENT IPA ADDRESS FORM
      invoiceRecipientIpaAddressForm: {},
      invoiceRecipientIpaAddressFormInitial: {},
      isInvoiceRecipientIpaAddressFormChanged: false,
      resetInvoiceRecipientIpaAddress: false,

      // INVOICE BANK FORM
      invoiceBankForm: {},
      invoiceBankFormInitial: {},
      isInvoiceBankFormChanged: false,
      resetInvoiceBank: false,

      // FORM INVOICEROWS
      formInvoiceRows: [],
      formInvoiceRowsInitial: [],
      isFormInvoiceRowChanged: false,
      resetInvoiceRows: false,
      invoiceRowsToChange: new Set()
    }
  },
  created () {
    this.$set(this, 'formEditable', false)
    this.setDetail({})
  },
  computed: {
    ...mapGetters({
      ...INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS,
      recipientInvoiceAddressDetail: RECIPIENT_INVOICE_TYPES.GENERIC.invoice.recipientinvoiceaddress.DETAIL.GETTERS.detail,
      invoiceBankDetail: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.GETTERS.detail,
      invoiceRowList: INVOICE_ROW_TYPES.GENERIC.invoice.invoicerow.LIST.GETTERS.list,
      invoiceVatRowList: VAT_ROW_TYPES.GENERIC.invoice.vatrow.LIST.GETTERS.list,
      recipientIpaAddressDetail: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.GETTERS.detail

    }),
    checkVatOrSsn () {
      if (this.formInvoiceAddress.ssn && this.formInvoiceAddress.ssn !== '') return true
      if (this.formInvoiceAddress.vat_number && this.formInvoiceAddress.vat_number !== '') return true
      return false
    },
    getQuotationRows () {
      if (this.quotationRowsList && this.quotationRowsList.length > 0) {
        const quotationRowsList = cloneDeep(this.quotationRowsList)
        return quotationRowsList.map((row) => {
          row.category_order = parseFloat(row.category_order.toString())
          row.row_order = parseFloat(row.row_order.toString())
          return row
        })
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setDetail: INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.MUTATIONS.setDetail,
      setInvoiceBankDetail: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      ...INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.ACTIONS,
      recipientInvoiceAddressRetrieve: RECIPIENT_INVOICE_TYPES.GENERIC.invoice.recipientinvoiceaddress.DETAIL.ACTIONS.retrieve,
      createRecipientInvoiceAddress: RECIPIENT_INVOICE_TYPES.GENERIC.invoice.recipientinvoiceaddress.DETAIL.ACTIONS.create,
      updateRecipientInvoiceAddress: RECIPIENT_INVOICE_TYPES.GENERIC.invoice.recipientinvoiceaddress.DETAIL.ACTIONS.update,
      invoiceBankRetrieve: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.ACTIONS.retrieve,
      createInvoiceBank: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.ACTIONS.create,
      updateInvoiceBank: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.ACTIONS.update,
      setInvoiceRowFilters: INVOICE_ROW_TYPES.GENERIC.invoice.invoicerow.LIST.ACTIONS.setFilters,
      setInvoiceVatRowFilters: VAT_ROW_TYPES.GENERIC.invoice.vatrow.LIST.ACTIONS.setFilters,
      invoiceRowUpdate: INVOICE_ROW_TYPES.GENERIC.invoice.invoicerow.DETAIL.ACTIONS.partialUpdate,
      organizationIpaAddressRetrieve: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.ACTIONS.retrieve,
      organizationIpaAddressCreate: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.ACTIONS.create,
      organizationIpaAddressUpdate: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.ACTIONS.update,
      organizationIpaAddressDelete: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.ACTIONS.delete
    }),
    makeFormEditable () {
      this.$set(this, 'formEditable', true)
    },
    makeFormReadonly () {
      this.$set(this, 'formEditable', false)
    },
    init () {
      this.cleanErrors()
      this.initFields()
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.makeFormReadonly()
            this.setForm()
            if (this.detail.recipient_invoice_address) {
              this.recipientInvoiceAddressRetrieve({ id: this.detail.recipient_invoice_address }).then(() => {
                this.$set(this, 'invoiceRecipientAddressFormInitial', cloneDeep(this.recipientInvoiceAddressDetail))
                this.$set(this, 'invoiceRecipientAddressForm', cloneDeep(this.recipientInvoiceAddressDetail))
                this.$set(this, 'isInvoiceRecipientAddressFormChanged', false)
              })
            } else {
              this.$set(this, 'invoiceRecipientAddressFormInitial', { })
              this.$set(this, 'invoiceRecipientAddressForm', { })
              this.$set(this, 'isInvoiceRecipientAddressFormChanged', false)
            }
            if (this.detail.recipient_typology === 'PA') {
              this.organizationIpaAddressRetrieve({ id: this.detail.issuer_ipa_address }).then(() => {
                this.$set(this, 'invoiceRecipientIpaAddressFormInitial', cloneDeep(this.recipientIpaAddressDetail))
                this.$set(this, 'invoiceRecipientIpaAddressForm', cloneDeep(this.recipientIpaAddressDetail))
                this.$set(this, 'isInvoiceRecipientIpaAddressFormChanged', false)
              })
            } else {
              this.$set(this, 'invoiceRecipientIpaAddressFormInitial', { })
              this.$set(this, 'invoiceRecipientIpaAddressForm', { })
              this.$set(this, 'isInvoiceRecipientIpaAddressFormChanged', false)
            }
            if (this.detail.issuer_bank) {
              this.invoiceBankRetrieve({ id: this.detail.issuer_bank }).then(() => {
                this.$set(this, 'invoiceBankFormInitial', cloneDeep(this.invoiceBankDetail))
                this.$set(this, 'invoiceBankForm', { ...this.invoiceBankDetail })
                this.$set(this, 'isInvoiceBankFormChanged', false)
              })
            } else {
              this.setInvoiceBankDetail({})
              this.$set(this, 'invoiceBankFormInitial', { })
              this.$set(this, 'invoiceBankForm', { })
              this.$set(this, 'isInvoiceBankFormChanged', false)
            }
            this.setInvoiceRowFilters({ filter__invoice_id: this.id, no_page: 'no_page' }).then(() => {
              this.$set(this, 'isFormInvoiceRowChanged', false)
              this.$set(this, 'formInvoiceRows', cloneDeep(this.invoiceRowList))
              this.$set(this, 'formInvoiceRowsInitial', cloneDeep(this.invoiceRowList))
            })
            this.setInvoiceVatRowFilters({ filter__invoice_id: this.id, no_page: 'no_page' })
          }
        )
      }
    },
    setForm () {
      const form = Object.assign({}, cloneDeep(this.detail))
      this.$set(this, 'form', cloneDeep(form))
      this.$set(this, 'formInitial', cloneDeep(form))
      this.$set(this, 'isFormChanged', false)
    },
    goToList () {
      this.$router.push({ name: INVOICE_ROUTES.INVOICE_INVOICE_LIST })
    },
    updateForm (form) {
      form = { ...this.form, ...form }
      this.$set(this, 'form', form)
      this.$set(this, 'isFormChanged', false)
      if (!checkIfEqual(form, this.formInitial)) {
        this.$set(this, 'isFormChanged', true)
      }
    },
    checkIfFormIsEmpty (form) {
      // if (form.address_scopes && form.address_scopes.length > 0) return false
      // if (form.ssn && form.ssn !== '') return false
      // if (form.vat_number && form.vat_number !== '') return false
      // if (form.sdi_code && form.sdi_code !== '') return false
      // if (form.email && form.email !== '') return false
      // if (form.name && form.name !== '') return false
      // if (form.number && form.number !== '') return false
      // if (form.zip_code && form.zip_code !== '') return false
      // if (form.locality && form.locality !== '') return false
      // if (form.province && form.province !== '') return false
      // if (form.country && form.country !== '') return false
      // return true
    },
    cancel () {
      this.init()
      this.makeFormReadonly()
      this.$set(this, 'resetForm', !this.resetForm)
      this.$set(this, 'newRecipient', undefined)
      this.$set(this, 'resetInvoiceRecipientAddress', !this.resetInvoiceRecipientAddress)
      this.$set(this, 'resetInvoiceBank', !this.resetInvoiceBank)
      this.$set(this, 'isFormInvoiceRowChanged', false)
      this.$set(this, 'invoiceRowsToChange', new Set())
    },
    createOrUpdateForm (form, createName, updateName) {
      if (!form.id) {
        form.invoice = this.detail.id
        return this[createName](form)
      } else {
        return this[updateName](form)
      }
    },
    updateInvoiceModels (formData, formInvoiceAddressData, formInvoiceBankData, formInvoiceIpaAddressData) {
      const promises = []
      if (this.isFormInvoiceAddressChanged) {
        promises.push(this.createOrUpdateForm(
          formInvoiceAddressData,
          'createRecipientInvoiceAddress',
          'updateRecipientInvoiceAddress'
        ))
      }
      if (this.isInvoiceRecipientIpaAddressFormChanged) {
        if (!formInvoiceIpaAddressData.ipa_code) {
          if (formInvoiceIpaAddressData.id) {
            promises.push(this.organizationIpaAddressDelete({ id: formInvoiceIpaAddressData.id }))
          }
        } else {
          formInvoiceIpaAddressData.holder = formData.recipient
          promises.push(this.createOrUpdateForm(
            formInvoiceIpaAddressData,
            'organizationIpaAddressCreate',
            'organizationIpaAddressUpdate'
          ))
        }
      }
      if (this.isInvoiceBankFormChanged) {
        promises.push(this.createOrUpdateForm(
          formInvoiceBankData,
          'createInvoiceBank',
          'updateInvoiceBank'
        ))
      }
      if (this.isFormInvoiceRowChanged) {
        this.formInvoiceRows.forEach((form) => {
          if (this.invoiceRowsToChange.has(form.id)) {
            promises.push(this.invoiceRowUpdate(form))
          }
        })
      }
      Promise.all(promises).then(() => {
        this.init()
      })
    },
    onSubmit () {
      const formData = Object.assign({}, this.form)
      const formInvoiceAddressData = Object.assign({}, this.invoiceRecipientAddressForm)
      const formInvoiceBankData = Object.assign({}, this.invoiceBankForm)
      const formInvoiceIpaAddressData = Object.assign({}, this.invoiceRecipientIpaAddressForm)
      if (this.isFormChanged) {
        this.update(formData).then(() => {
          this.updateInvoiceModels(formData, formInvoiceAddressData, formInvoiceBankData, formInvoiceIpaAddressData)
        })
      } else {
        this.updateInvoiceModels(formData, formInvoiceAddressData, formInvoiceBankData, formInvoiceIpaAddressData)
      }
    },
    setRecipientAddressForm (invoiceRecipientAddressForm) {
      this.$set(this, 'isFormInvoiceAddressChanged', false)
      invoiceRecipientAddressForm = { ...this.invoiceRecipientAddressForm, ...invoiceRecipientAddressForm }
      this.$set(this, 'invoiceRecipientAddressForm', invoiceRecipientAddressForm)
      if (!checkIfEqual(invoiceRecipientAddressForm, this.invoiceRecipientAddressFormInitial)) {
        this.$set(this, 'isFormInvoiceAddressChanged', true)
      }
    },
    setIpaAddressForm (invoiceRecipientIpaAddressForm) {
      this.$set(this, 'isInvoiceRecipientIpaAddressFormChanged', false)
      invoiceRecipientIpaAddressForm = { ...this.invoiceRecipientIpaAddressForm, ...invoiceRecipientIpaAddressForm }
      this.$set(this, 'invoiceRecipientIpaAddressForm', invoiceRecipientIpaAddressForm)
      if (!checkIfEqual(invoiceRecipientIpaAddressForm, this.invoiceRecipientAddressFormInitial)) {
        this.$set(this, 'isInvoiceRecipientIpaAddressFormChanged', true)
      }
    },
    setInvoiceBankForm (invoiceBankForm) {
      this.$set(this, 'isInvoiceBankFormChanged', false)
      invoiceBankForm = { ...this.invoiceBankForm, ...invoiceBankForm }
      this.$set(this, 'invoiceBankForm', invoiceBankForm)
      if (!checkIfEqual(invoiceBankForm, this.invoiceBankFormInitial)) {
        this.$set(this, 'isInvoiceBankFormChanged', true)
      }
    },
    updateInvoiceForm (evt) {
      this.$set(this.form, 'payment_form', evt.payment_form)
      this.$set(this.form, 'payment_form_name', evt.payment_form_name)
      this.$set(this.form, 'deadline', evt.deadline)
      this.$set(this, 'isFormChanged', false)
      if (!checkIfEqual(this.form, this.formInitial)) {
        this.$set(this, 'isFormChanged', true)
      }
    },
    changeInvoiceOwner (evt) {
      this.$set(this, 'changeRecipient', !this.changeRecipient)
      this.$set(this, 'newRecipient', evt)
    },
    updateRows (rows, rowsToChanged) {
      const formInvoiceRows = rows
      this.$set(this, 'formInvoiceRows', rows)
      this.$set(this, 'isFormInvoiceRowChanged', false)
      this.$set(this, 'invoiceRowsToChange', rowsToChanged)
      if (!checkIfEqual(formInvoiceRows, this.formInvoiceRowsInitial) || formInvoiceRows.length !== this.formInvoiceRowsInitial.length) {
        this.$set(this, 'isFormInvoiceRowChanged', true)
      }
    },
    showIpaAddressComponent () {
      if (this.newRecipient) {
        if (this.newRecipient.typology === 'PA') {
          return true
        }
        return false
      } else if (this.detail.recipient_typology === 'PA') {
        return true
      }
      return false
    }
  },
  validations () {
    const validator = {
      form: {
        number: { required },
        date: { required },
        invoice_year: { required },
        recipient: { required },
        typology: { required },
        note: {},
        payment_form: { required },
        deadline: { required }
      },
      invoiceRecipientAddressForm: {
        recipient: { required },
        ssn: { },
        vat_number: { },
        phone: { required },
        email: { required },
        name: { required },
        number: {
          maxLength: maxLength(20)
        },
        zip_code: {
          required,
          maxLength: maxLength(20)
        },
        locality: { required },
        province: {
          required,
          maxLength: maxLength(2)
        },
        country: { required }
      },
      invoiceBankForm: {
        name: { },
        iban: {
          maxLength: maxLength(34)
        },
        abi: {
          maxLength: maxLength(5)
        },
        cab: {
          maxLength: maxLength(5)
        },
        int_code: {
          maxLength: maxLength(2)
        },
        cin: {
          maxLength: maxLength(1)
        },
        cc: {
          maxLength: maxLength(12)
        }

      }
    }
    if (this.invoiceBankForm.name) {
      validator.invoiceBankForm.iban = { required, maxLength: maxLength(34) }
    } else {
      validator.invoiceBankForm.iban = { maxLength: maxLength(34) }
    }
    if (this.invoiceBankForm.iban) {
      validator.invoiceBankForm.name = { required }
    } else {
      validator.invoiceBankForm.name = { }
    }
    return validator
  }
}
</script>
