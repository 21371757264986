<template>
  <panel :title="$t('Recipient IPA address')" noButton="true">
    <div>
      <b-form class="mb-3" v-if="showselectAddressButton" >
        <b-button class="mr-2" size="md" variant="primary" @click.prevent="openSelectAddressModal()">
          <i class="fas fa-map-pin mr-1"></i>
          {{ 'Select Ipa address' | translate }}
        </b-button>
      </b-form>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.recipient"
            :external-errors="addressErrors['recipient']"
            :label-form="'Recipient' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.recipient"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'recipient', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.ipa_code"
            :external-errors="addressErrors['ipa_code']"
            :label-form="'Identification code PA (IPA)' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.ipa_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'ipa_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.cig_code"
            :external-errors="addressErrors['cig_code']"
            :label-form="'CIG code' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cig_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'cig_code', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.cup_code"
            :external-errors="addressErrors['cup_code']"
            :label-form="'CUP code' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cup_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'cup_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.recipient_document_number"
            :external-errors="addressErrors['recipient_document_number']"
            :label-form="'Recipient document number' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.recipient_document_number"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'recipient_document_number', $event)"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.recipient_document_date"
            :label-form="'Recipient document date' | translate"
            :disabled="!formEditableUpdate"
            class-form="col-6 mb-2 mt-2"
            type="date"
            :value="recDocumentDate"
            :min-date="form.recipient_document_date"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'recipient_document_date')"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.address_type"
            :external-errors="addressErrors['address_type']"
            :label-form="'Address Type' | translate"
            class-form="col-6 mb-2 mt-2"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            :value="form.address_type ? { value: form.address_type, name: form.address_type_name } : null"
            :options="addressTypeListOptions"
            :placeholder="$t('Select a address type')"
            :placeholder-field="form.address_type_name"
            @select="selectAddressType"
            @clear="clearAddressType"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="addressErrors['name']"
            :label-form="'Address' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.name"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.number"
            :external-errors="addressErrors['number']"
            :label-form="'Number' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.number"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'number', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.zip_code"
            :external-errors="addressErrors['zip_code']"
            :label-form="'CAP' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.zip_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'zip_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.locality"
            :external-errors="addressErrors['locality']"
            :label-form="'Locality' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.locality"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'locality', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.province"
            :external-errors="addressErrors['province']"
            :label-form="'Province (abbr.)' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            maxlength="2"
            :value="form.province"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'province', $event ? $event.toUpperCase() : null)"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.country"
            :external-errors="addressErrors['country']"
            :label-form="'Country' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            :value="form.country ? { value: form.country, name: form.country_name } : null"
            :options="countryListOptions"
            :placeholder="$t('Type a country')"
            :placeholder-field="form.country_name"
            @select="selectCountry"
            @clear="clearCountry"
          />
        </b-form-row>
      </b-form>
    </div>
    <select-ipa-address-modal v-if="showSelectAddressModal" :list="ipaAddressList" :orgId="newRecipient.id" v-on:close-select-address="closeSelectAddressModal"></select-ipa-address-modal>
  </panel>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { TYPES as INVOCE_TYPES } from '../store'
import { TYPES as ORGANIZATION_IPA_ADDRESS_TYPES } from '../../organization-ipa-address/store'
import { TYPES as ORG_IPA_ADDRESS_TYPES } from '../../../organization/organization/organization-ipa-address/store'
import moment from 'moment'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import CountryMixin from '../../../organization/organization/organization-address/mixins/CountryMixin'
import AddressTypeMixin from '../../../organization/organization/organization-address/mixins/AddressTypeMixin'
import SelectIpaAddressModal from '../../../organization/organization/organization-ipa-address/OrganizationIpaAddressModal'

export default {
  name: 'RecipientIpaInvoiceAddressEditForm',
  components: {
    SelectIpaAddressModal
  },
  mixins: [
    ThuxDetailPanelMixin, CountryMixin, AddressTypeMixin
  ],
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {},
    owner: {},
    changeRecipient: {},
    newRecipient: {}
  },
  watch: {
    detail () {
      this.init()
    },
    recipientInvoiceIpaAddressDetail: {
      handler () {
        if (this.recipientInvoiceIpaAddressDetail) {
          this.$set(this, 'form', this.recipientInvoiceIpaAddressDetail)
        }
        if (this.recipientInvoiceIpaAddressDetail.recipient_document_date) {
          this.$set(this, 'recDocumentDate', moment(new Date(this.recipientInvoiceIpaAddressDetail.recipient_document_date)))
        }
      }
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    formEditableUpdate () {
      this.$set(this, 'formEditable', this.formEditableUpdate)
    },
    resetForm () {
      if (this.recipientInvoiceIpaAddressDetail) {
        this.$set(this, 'form', this.recipientInvoiceIpaAddressDetail)
      }
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    },
    changeRecipient () {
      this.$set(this, 'form', {})
      if (this.newRecipient) {
        if (this.newRecipient.typology === 'PA') {
          this.$set(this, 'showselectAddressButton', true)
        } else {
          this.resetIpaAddressForm()
        }
      } else {
        this.$set(this, 'showselectAddressButton', false)
      }
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    }
  },
  data () {
    return {
      form: {},
      showselectAddressButton: false,
      showSelectAddressModal: false,
      selectedAddress: {},
      resetFormAddress: false,
      recDocumentDate: undefined,
      deleteIpaAddress: false
    }
  },
  computed: {
    ...mapGetters({
      addressErrors: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.GETTERS.errors,
      recipientInvoiceIpaAddressDetail: ORGANIZATION_IPA_ADDRESS_TYPES.GENERIC.invoice.organizationipaaddress.DETAIL.GETTERS.detail,
      detail: INVOCE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS.detail,
      ipaAddressList: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.GETTERS.list
    })
  },
  methods: {
    init () {
      this.$set(this, 'showselectAddressButton', false)
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      }
    },
    openSelectAddressModal () {
      this.showSelectAddressModal = true
    },
    resetIpaAddressForm () {
      this.$set(this.form, 'recipient', undefined)
      this.$set(this.form, 'cup_code', undefined)
      this.$set(this.form, 'ipa_code', undefined)
      this.$set(this.form, 'cig_code', undefined)
      this.$set(this.form, 'address_type', undefined)
      this.$set(this.form, 'address_type_name', undefined)
      this.$set(this.form, 'name', undefined)
      this.$set(this.form, 'number', undefined)
      this.$set(this.form, 'zip_code', undefined)
      this.$set(this.form, 'locality', undefined)
      this.$set(this.form, 'province', undefined)
      this.$set(this.form, 'country', undefined)
      this.$set(this.form, 'country_name', undefined)
      this.$set(this.form, 'recipient_document_name', undefined)
      this.$set(this.form, 'recipient_document_date', undefined)
      this.$set(this, 'recDocumentDate', undefined)
    },
    closeSelectAddressModal (evtData) {
      if (evtData) {
        this.$set(this.form, 'recipient', evtData.recipient)
        this.$set(this.form, 'cup_code', evtData.cup_code)
        this.$set(this.form, 'ipa_code', evtData.ipa_code)
        this.$set(this.form, 'cig_code', evtData.cig_code)
        this.$set(this.form, 'address_type', evtData.address_type)
        this.$set(this.form, 'address_type_name', evtData.address_type_name)
        this.$set(this.form, 'name', evtData.name)
        this.$set(this.form, 'number', evtData.number)
        this.$set(this.form, 'zip_code', evtData.zip_code)
        this.$set(this.form, 'locality', evtData.locality)
        this.$set(this.form, 'province', evtData.province)
        this.$set(this.form, 'country', evtData.country)
        this.$set(this.form, 'country_name', evtData.country_name)
      }
      this.showSelectAddressModal = false
    }
  },
  validations () {
    const validator = {
      form: {
        recipient: { required },
        ipa_code: { required },
        cig_code: { },
        cup_code: { },
        address_type: {},
        name: { required },
        number: {
          maxLength: maxLength(20)
        },
        zip_code: {
          required,
          maxLength: maxLength(20)
        },
        locality: { required },
        province: {
          required,
          maxLength: maxLength(2)
        },
        country: { required },
        recipient_document_name: {},
        recipient_document_date: {}
      }
    }
    // validator.form = this.getAddressValidator(validator.form)
    return validator
  }
}
</script>
