<template>
  <panel :title="$t('Payment data')" noButton="true">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.invForm.payment_form"
            :label-form="'PA XML Payment Form' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            :value="invForm.payment_form ? { value: invForm.payment_form, name: invForm.payment_form_name } : null"
            :options="paymentFormListOptions"
            :placeholder="$t('Type a payment form')"
            :placeholder-field="invForm.payment_form_name"
            @search="onSearchPaymentForm"
            @select="selectPaymentForm"
            @clear="clearPaymentForm"
          />
        <form-thux-horizontal-datetime
          :validator="$v.invForm.deadline"
          :label-form="'Deadline' | translate"
          :disabled="!formEditableUpdate"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="invForm.deadline"
          :placeholder="$t('Enter the date')"
          @change="setDeadline"
        />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="bankErrors['name']"
            :label-form="'Bank name' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.name"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'name', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.iban"
            :external-errors="bankErrors['iban']"
            :label-form="'IBAN' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.iban"
            :disabled="!formEditableUpdate"
            @change="setIbanCode($event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.abi"
            :external-errors="bankErrors['abi']"
            :label-form="'ABI' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.abi"
            :disabled="true"
            @change="$set(form, 'abi', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.cab"
            :external-errors="bankErrors['cab']"
            :label-form="'CAB' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cab"
            :disabled="true"
            @change="$set(form, 'cab', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.cin"
            :external-errors="bankErrors['cin']"
            :label-form="'CIN' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cin"
            :disabled="true"
            @change="$set(form, 'cin', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.int_code"
            :external-errors="bankErrors['int_code']"
            :label-form="'Int. Code' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.int_code"
            :disabled="true"
            @change="$set(form, 'int_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.cc"
            :external-errors="bankErrors['cc']"
            :label-form="'CC' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cc"
            :disabled="true"
            @change="$set(form, 'cc', $event)"
          />
        </b-form-row>
      </b-form>
    </div>
    <select-address-modal v-if="showSelectAddressModal" :orgId="owner" v-on:close-select-address="closeSelectAddressModal"></select-address-modal>
  </panel>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { TYPES as INVOCE_TYPES } from '../store'
import { TYPES as INVOICE_ISSUER_BANK_TYPES } from '../../invoice-issuer-bank/store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import PaymentFormMixin from '../mixins/PaymentFormMixin'
import moment from 'moment'

export default {
  name: 'InvoiceBankEditForm',
  components: {},
  mixins: [
    ThuxDetailPanelMixin, PaymentFormMixin
  ],
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {},
    owner: {}
  },
  watch: {
    detail () {
      this.init()
    },
    invoiceBankDetail: {
      handler () {
        if (this.invoiceBankDetail) {
          this.$set(this, 'form', this.invoiceBankDetail)
        }
      }
    },
    invForm: {
      handler () {
        this.$emit('update-invoice-form', this.invForm)
      },
      deep: true
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    formEditableUpdate () {
      this.$set(this, 'formEditable', this.formEditableUpdate)
    },
    resetForm () {
      this.setForm()
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    }
  },
  data () {
    return {
      form: {},
      invForm: {},
      deadline: undefined,
      showSelectAddressModal: false,
      selectedAddress: {},
      resetFormAddress: false
    }
  },
  computed: {
    ...mapGetters({
      bankErrors: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.GETTERS.errors,
      invoiceBankDetail: INVOICE_ISSUER_BANK_TYPES.GENERIC.invoice.invoiceissuerbank.DETAIL.GETTERS.detail,
      detail: INVOCE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS.detail
    })
  },
  methods: {
    init () {
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      }
    },
    setForm () {
      if (this.detail) {
        if (this.detail.deadline) {
          this.$set(this.invForm, 'deadline', moment(new Date(this.detail.deadline)))
        } else {
          this.$set(this.invForm, 'deadline', undefined)
        }
        if (this.detail.payment_form) {
          this.$set(this.invForm, 'payment_form', this.detail.payment_form)
          this.$set(this.invForm, 'payment_form_name', this.detail.payment_form_name)
        } else {
          this.$set(this.invForm, 'payment_form', undefined)
          this.$set(this.invForm, 'payment_form_name', undefined)
        }
      }
    },
    setDeadline (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.invForm, 'deadline', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.invForm, 'deadline', undefined)
      }
    },
    setIbanCode (evt) {
      this.$set(this.form, 'iban', evt)
      if (evt) {
        this.$set(this.form, 'int_code', evt.substring(0, 2))
        this.$set(this.form, 'cin', evt.substring(4, 5))
        this.$set(this.form, 'abi', evt.substring(5, 10))
        this.$set(this.form, 'cab', evt.substring(10, 15))
        this.$set(this.form, 'cc', evt.substring(15))
      } else {
        this.$set(this.form, 'abi', undefined)
        this.$set(this.form, 'cab', undefined)
        this.$set(this.form, 'int_code', undefined)
        this.$set(this.form, 'cin', undefined)
        this.$set(this.form, 'cc', undefined)
      }
    }
  },
  validations () {
    const validator = {
      invForm: {
        payment_form: { required },
        deadline: { required }
      },
      form: {
        name: { },
        iban: {
          maxLength: maxLength(34)
        },
        abi: {
          maxLength: maxLength(5)
        },
        cab: {
          maxLength: maxLength(5)
        },
        int_code: {
          maxLength: maxLength(2)
        },
        cin: {
          maxLength: maxLength(1)
        },
        cc: {
          maxLength: maxLength(12)
        }
      }
    }
    if (this.form.name) {
      validator.form.iban = { required, maxLength: maxLength(34) }
    } else {
      validator.form.iban = { maxLength: maxLength(34) }
    }
    if (this.form.iban) {
      validator.form.name = { required }
    } else {
      validator.form.name = { }
    }
    return validator
  }
}
</script>
