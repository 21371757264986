<template>
  <div class="panel-services-manager">
    <panel :title="'VAT Rows' | translate" noButton="true" panel-class="panel-services">
      <div class="col-12 installment-items px-0" v-for="(vatRow, index) in vatRows" :key="index">
        <invoice-vat-row-form
          :form-editable-update="formEditableUpdate"
          :form-update="vatRow"
          :rows-length="vatRows.length"
          :index="index"
        />
      </div>
    </panel>
  </div>
</template>

<script>
import VatRowForm from './VatRowForm'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

export default {
  name: 'InvoiceRowManager',
  props: {
    formEditableUpdate: {},
    invoiceVatRows: [],
    resetInvoiceVatRows: {}
  },
  components: {
    'invoice-vat-row-form': VatRowForm
  },
  watch: {
    resetInvoiceVatRows () {
      this.init()
    },
    invoiceVatRows () {
      this.init()
    }
  },
  data () {
    return {
      vatRows: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.invoiceVatRows && this.invoiceVatRows.length > 0) {
        this.$set(this, 'vatRows', cloneDeep(this.invoiceVatRows))
      } else {
        this.$set(this, 'vatRows', [])
      }
    }
  },
  validations: {
    vatRows: {
      $each: {
        name: { required },
        amount: { required },
        days: { },
        days_end_month: { },
        end_month: { }
      }
    }
  }
}
</script>
