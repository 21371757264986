<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.vat_name"
        :label-form="'Name' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.vat_name.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'vat_name', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.vat_aliquot_perc"
        :label-form="'VAT' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.vat_aliquot_perc.$model"
        labelColsSm="0"
        labelColsLg="0"
        placeholder="%"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'vat_aliquot_perc', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.total_income"
        :label-form="'Total income' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.total_income.$model"
        labelColsSm="0"
        labelColsLg="0"
        placeholder="€"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'total_income', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.total_vat"
        :label-form="'Total VAT' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.total_vat.$model"
        labelColsSm="0"
        labelColsLg="0"
        placeholder="€"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'total_vat', $event)"
      />
    </b-form-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import VatMixin from '../../../../product/product/mixins/VatMixin'

export default {
  name: 'InvoiceRowForm',
  mixins: [ThuxDetailPanelMixin, VatMixin],
  props: {
    formUpdate: {},
    index: {},
    rowsLength: {},
    formEditableUpdate: {}
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: { },
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
      }
    }
  },
  validations () {
    const form = {
      total_income: { required },
      total_vat: { required },
      vat_code: {},
      vat_name: {},
      vat_aliquot_perc: { required }
    }
    return { form: form }
  }
}
</script>
