<template>
  <panel :title="'Invoice totals' | translate" noButton="true">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-input
          :label-form="'Total income' | translate"
          class-form="input-row-col my-2 pl-2"
          type="text"
          labelColsSm="0"
          labelColsLg="0"
          placeholder="€"
          :value="totalIncome"
          :disabled="!formEditableUpdate"
        />
        <form-thux-horizontal-input
          :label-form="'Total VAT' | translate"
          class-form="input-row-col my-2 pl-2"
          type="text"
          labelColsSm="0"
          labelColsLg="0"
          placeholder="€"
          :value="totalVat"
          :disabled="!formEditableUpdate"
        />
        <form-thux-horizontal-input
          :label-form="'Total amount' | translate"
          class-form="input-row-col my-2 pl-2"
          type="text"
          labelColsSm="0"
          labelColsLg="0"
          placeholder="€"
          :value="totalAmount"
          :disabled="!formEditableUpdate"
        />
      </b-form-row>
    </b-form>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import { TYPES as INVOICE_TYPES } from '../store'
import moment from 'moment'

export default {
  name: 'InvoiceTotals',
  mixins: [ThuxDetailPanelMixin],
  components: {
  },
  props: {
    formEditableUpdate: {},
    totalVat: {},
    totalIncome: {},
    totalAmount: {}
  },
  watch: {
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    proposal () {
      this.init()
    },
    resetForm () {
      this.init()
    },
    id () {
      this.init()
    }
  },
  data () {
    return {
      dateI: undefined,
      dateFirmFor: undefined,
      supplierContacts: []
    }
  },
  computed: {
    ...mapGetters({
      detail: INVOICE_TYPES.GENERIC.invoice.invoice.DETAIL.GETTERS.detail
    })
  },
  methods: {
    init () {
      this.makeFormReadonly()
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      if (this.detail.date) {
        this.$set(this, 'dateI', moment(new Date(this.detail.date)))
      }
      this.$set(this, 'form', form)
    },
    setDateI (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'date', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'date', undefined)
      }
    }
  },
  validations () {
    const validator = {
      form: {
        date: { required },
        number: { required },
        invoice_year: { required },
        recipient: { required },
        note: { }
      }
    }
    return validator
  }
}
</script>
