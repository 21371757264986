<template>
  <div class="panel-services-manager">
    <panel :title="'Invoice Rows' | translate" noButton="true" panel-class="panel-services">
      <div class="col-12 installment-items px-0" v-for="(invRow, index) in invRows" :key="index">
        <invoice-row-form
          :form-editable-update="formEditableUpdate"
          :form-update="invRow"
          :index="index"
          @edit-row="editRow($event, index)"
        />
      </div>
    </panel>
  </div>
</template>

<script>
import InvoiceRowForm from './InvoiceRowForm'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

export default {
  name: 'InvoiceRowManager',
  props: {
    formEditableUpdate: {},
    invoiceRows: [],
    resetInvoiceRows: {}
  },
  components: {
    'invoice-row-form': InvoiceRowForm
  },
  watch: {
    resetInvoiceRows () {
      this.init()
    },
    invoiceRows () {
      this.init()
    },
    invRows: {
      handler () {
        let invRows = [...this.invRows]
        invRows = invRows.filter((row) => {
          return Object.values(row).some((value) => { return !!value })
        })
        this.$emit(
          'update-rows',
          invRows,
          this.rowsToChanged
        )
      },
      deep: true
    }
  },
  data () {
    return {
      invRows: [],
      rowsToChanged: new Set()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.invoiceRows && this.invoiceRows.length > 0) {
        this.$set(this, 'invRows', cloneDeep(this.invoiceRows))
      } else {
        this.$set(this, 'invRows', [])
      }
    },
    editRow (row, index) {
      this.rowsToChanged.add(row.id)
      this.$set(this.invRows, index, row)
    }
  },
  validations: {
    invRows: {
      $each: {
        id_row: { required },
        description: { required },
        amount_total: { required },
        vat_code: {},
        vat_aliquot_perc: { }
      }
    }
  }
}
</script>
